import { useState, useEffect } from 'react';
import { Form, FormProvider, SuperSchema } from '@updater/ui-informant';
import {
  Box,
  Alert,
  H2,
  H1,
  Button,
  GridLayoutColumn,
  GridLayout,
  P,
} from '@updater/ui-uds';
import { TAddress } from '@updater/ui-widgets';
import { FormData, AccountData, InviteData } from 'types';
import {
  UsersTable,
  InviteTable,
  FormQueryParamSerializer,
  useFormDataQueryParamOnce,
} from 'components';
import create from 'zustand';
import { persist } from 'zustand/middleware';

/*
 * API Docs
 * GET https://upd-dev-account-creator.updater.site/flows
 * retrieves all flows

 * POST https://upd-dev-account-creator.updater.site/account
 * creates a new account
 * post body payload:

 {
   "email": String,
   "flow": Enum("tvi" | "dolly")?,
   "dimensions": String?, // comma-separated string
   "state": String?,
   "firstName": String?,
   "lastName": String?,
   "moveDate": String?,
   "password: String?,
   "overrides": {
      "toEmpty": Boolean?,
      "fromEmpty": Boolean?,
    }
  }

type AccountPayload = {
  email: string;
  flow: 'tvi' | 'dolly';
  state: string;
  firstName?: string;
  lastName?: string;
  moveDate?: string;
  password?: string;
  overrides?: {
    toEmpty: boolean;
    fromEmpty: boolean;
  }
};
*/

const accountCreatorBase = 'https://upd-dev-account-creator.updater.site/';
// const accountCreatorBase = 'http://127.0.0.1:8787/';

const defaultInitialValues = {
  flow: 'robtasks',
  dimensions: ['pets', 'poi'],
};

function convertFormDataToApiInput({
  optionalFields,
  dimensions,
  moveDate,
  ...rest
}: FormData) {
  const restData = rest;
  if (restData.overrideData) {
    restData.overrides = Object.fromEntries(
      restData.overrideData.map((key) => [key, true])
    );
  }
  return {
    ...restData,
    dimensions: Array.isArray(dimensions) ? dimensions.join(',') : dimensions,
    moveDate: moveDate
      ? `${moveDate.slice(-4)}-${moveDate.slice(0, 2)}-${moveDate.slice(
          -7,
          -5
        )}`
      : undefined,
    email: rest.email,
    flow: rest.flow === ' ' ? undefined : rest.flow,
  };
}

function getError({ title }: { title: string }): string {
  if (
    title === 'From and To addresses in static json were both null or empty' ||
    title === 'No address with that key was found.'
  ) {
    return 'No account with that combination is possible.';
  }

  return 'Something went wrong. Please try a different combination';
}

const useAccounts = create<{
  users: AccountData[];
  addUser: (user: AccountData) => void;
  removeUser: (emailToRemove: string) => void;
}>(
  persist(
    (set, get) => ({
      users: [] as AccountData[],
      addUser: (user: AccountData) =>
        set({
          users: [...get().users, user],
        }),
      removeUser: (emailToRemove: string) =>
        set({
          users: get().users.filter(({ email }) => email !== emailToRemove),
        }),
    }),
    {
      name: 'CREATED_ACCOUNTS',
    }
  )
);

const useInvites = create<{
  invites: InviteData[];
  addInvite: (invite: InviteData) => void;
  removeInvite: (inviteToRemove: string) => void;
}>(
  persist(
    (set, get) => ({
      invites: [] as InviteData[],
      addInvite: (invite: InviteData) =>
        set({
          invites: [...get().invites, invite],
        }),
      removeInvite: (inviteToRemove: string) =>
        set({
          invites: get().invites.filter(
            ({ inviteToken }) => inviteToken !== inviteToRemove
          ),
        }),
    }),
    {
      name: 'CREATED_INVITES',
    }
  )
);

function DynamicFormContainer({ schema, handleSubmit }) {
  const initialValues = useFormDataQueryParamOnce() ?? defaultInitialValues;

  return (
    <FormProvider
      initialValues={initialValues}
      schema={schema as SuperSchema}
      onSubmit={handleSubmit}
    >
      <FormQueryParamSerializer />
      <Form />
    </FormProvider>
  );
}

export default function AccountForm() {
  const { users, addUser, removeUser } = useAccounts();
  const { invites, addInvite, removeInvite } = useInvites();

  // const users = [];
  const [error, setError] = useState('');
  const [schema, setSchema] = useState(null);

  const handleSubmit = (_values: unknown) => {
    const values = _values as FormData;
    console.log(
      'Submitting -----',
      convertFormDataToApiInput(values as FormData)
    );
    // console.log(convertFormDataToApiInput(values as FormData));
    const { accountState } = values;
    const path = `${accountCreatorBase}${
      accountState === 'inviteOnly' ? 'invite' : 'account'
    }`;
    console.log(path);
    return fetch(path, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(convertFormDataToApiInput(values as FormData)),
    })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        console.log('Response--', data, errors);

        console.log(accountState);
        console.log(errors);
        if (errors.length > 0) {
          setError(getError(errors?.[0] ?? {}));
          return;
        }

        setError('');
        if (accountState === 'fullAccount') {
          const password: string = data?.inputs?.password;
          const {
            accessToken,
            uid,
            client,
          }: {
            accessToken: string;
            uid: string;
            client: string;
          } = data?.signupMover?.session ?? {};
          const {
            firstName,
            lastName,
            email,
            uuid,
          }: {
            firstName: string;
            lastName: string;
            email: string;
            uuid: string;
          } = data?.signupMover?.mover || {};
          const {
            fromAddress,
            toAddress,
          }: {
            fromAddress: TAddress;
            toAddress: TAddress;
          } = data?.signupMover?.mover?.currentMove || {};
          addUser({
            password,
            accessToken,
            uid,
            client,
            firstName,
            lastName,
            email,
            uuid,
            fromAddress,
            toAddress,
            flow: values.flow,
            dimensions: values.dimensions,
          });
        } else if (accountState === 'inviteOnly') {
          console.log('SAVING INVITE');
          console.log(data);
          const inviteToken = data?.inviteData.token;
          const email = data?.inviteData.email;
          const firstName = data?.inviteData.first_name;
          const lastName = data?.inviteData.last_name;
          const fromAddress: TAddress = data?.inviteData.to_address;
          const toAddress: TAddress = data?.inviteData.from_address;
          const dimensions = data?.inputData?.dimensions;
          const flow = data?.inputData?.flow;
          const thisInvite = {
            inviteToken,
            email,
            firstName,
            lastName,
            fromAddress,
            toAddress,
            dimensions,
            flow,
          };

          console.log(thisInvite);
          addInvite(thisInvite);
        }
      })
      .catch((e, ...rest) => {
        console.error('An error occured!');
        console.error(e, rest);
      });
  };

  useEffect(() => {
    fetch(`${accountCreatorBase}informant-config`)
      .then((res) => res.json())
      .then((informantData) => {
        setSchema(informantData);
        console.log(informantData);
      });
  }, []);

  return (
    <GridLayout>
      <GridLayoutColumn span="6">
        <Box backgroundColor="warning" p="m">
          <H1 variant="xxl">
            This is a non-production app for creating staging accounts and
            invites
          </H1>
        </Box>
        <Box my="xl">
          {schema !== null && (
            <DynamicFormContainer schema={schema} handleSubmit={handleSubmit} />
          )}
        </Box>
        {!!error && <Alert variant="error" message={error} />}
      </GridLayoutColumn>
      <GridLayoutColumn spanStart="8" span="4">
        <H1 variant="lBold">Updater Staging Account Creator</H1>
        <P variant="s">
          This tool allows you to create accounts which meet certain
          specifications. Accounts created will be stored locally in your
          browser for future use. If you run into an issue, or need more
          functionality, please file a story card by{' '}
          <a
            href="https://app.shortcut.com/updater/stories/new?template_id=62290d54-7868-477e-843b-50aad7cc620d"
            target="_blank"
            rel="noreferrer"
          >
            clicking here
          </a>
          .
        </P>
        <Box>
          <P variant="mBold">
            As of now the buttons do not log you in automatically, so please
            make sure to log out and then log in with the new user you created
          </P>
        </Box>
      </GridLayoutColumn>
      <GridLayoutColumn span="12">
        <Box my="xl">
          <H2 variant="xxlBold">Created Users</H2>
          <UsersTable users={users} removeUser={removeUser} />
        </Box>
        <Box my="xl">
          <H2 variant="xxlBold">Created Invites</H2>
          <InviteTable invites={invites} removeInvite={removeInvite} />
        </Box>
      </GridLayoutColumn>
    </GridLayout>
  );
}
